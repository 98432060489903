import { db } from '@/firebase/config'


const sendAlumniReminder = async (alumniName, alumniEmail, appNumber, oppName, oppCompany) => {
  try {
    await db.collection("mail").add({
      to: alumniEmail,
      template: {
        name: 'AlumniReminder',
        data: {
          appNumber: appNumber,
          alumniName: alumniName,
          oppName: oppName,
          oppCompany: oppCompany
        }
      }
    })
    // console.log("sendAlumniReminder activated.")
  } catch (error) {
    alert("sendAlumniReminder: " + error)
  }
};

export default sendAlumniReminder

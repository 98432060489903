import { db } from '@/firebase/config'
import { ref } from "vue";
import getMyOpps from "@/composables/Profile/getMyOpps"
import sendAlumniReminder from "@/composables/Email/sendAlumniReminder"
import { getProfile } from "@/composables/Profile/getProfile"
import { analytics } from '../../firebase/config';

export const isRegistered = async (userId, oppId) => {
  try {
    let flag = false
    await db.collection('Opportunities').doc(oppId).collection("registered").get().then(snapshot => {
      snapshot.docs.forEach(doc => {
        if (doc.data().uid == userId) {
          flag = true
        }
      })
    })
    // console.log(flag)
    return flag
  } catch (error) {
    alert(error)
  }
}

const register = async (userId, oppId, registerFlag) => {
  try {
    const myOpps = ref(null);
    const alumniProfile = ref(null);
    let applicantsCounter = 0;
    let appMilestone = 0;
    let alumniId, oppName, oppCompany;
    await getMyOpps(myOpps, userId)
    if (myOpps.value.length >= 5) {
      throw new TypeError("Failed: You are not allowed to register for more than 5 opportunities.")
    }
    await db.collection('Opportunities').doc(oppId).collection("registered").add({
      status: "pending",
      uid: userId
    })
    if (typeof alert !== "undefined") {
      // alert("Your request has been sent!")
    }
    registerFlag.value = !registerFlag.value;
    await db.collection('Opportunities').doc(oppId).get().then(doc => {
      appMilestone = doc.data().appMilestone;
      alumniId = doc.data().alumniId;
      oppName = doc.data().title;
      oppCompany = doc.data().company;
    })
    await db.collection('Opportunities').doc(oppId).collection("registered").get().then(docs => {
      docs.forEach(() => {
        applicantsCounter++;
      })
    })
    await getProfile(alumniProfile, alumniId);
    analytics.logEvent("opportunity_requested", {"companyName": oppCompany,"alumniName": alumniProfile.value.firstName});
    if (applicantsCounter == appMilestone) {
      await sendAlumniReminder(alumniProfile.value.firstName, alumniProfile.value.email, applicantsCounter, oppName, oppCompany);
      if (appMilestone == 5){
        appMilestone = 10;
      } else if (appMilestone == 10){
        appMilestone = 20;
      } else if (appMilestone == 20){
        appMilestone = 50;
      } else if (appMilestone == 50){
        appMilestone = 10000;
      }
      await db.collection('Opportunities').doc(oppId).update({
        appMilestone: appMilestone
      })
    }
  } catch (error) {
    alert(error.message);
  }
}

const unRegister = async (userId, oppId, registerFlag) => {
  try {
    await db.collection('Opportunities').doc(oppId).collection("registered").where("uid", "==", userId).get().then(async docs => {
      let promises = []
      docs.forEach(doc => {
        promises.push(doc.ref.delete())
      })
      await Promise.all(promises)
      if (typeof alert !== "undefined") {
        // alert("Your request has been withdrawn.")
      }
      registerFlag.value = !registerFlag.value;
    })
  } catch (error) {
    alert(error);
  }
}


export const toggleRegister = async (userId, oppId, registerFlag) => {
  let flag = await isRegistered(userId, oppId)
  if (flag) {
    await unRegister(userId, oppId, registerFlag)
  }
  else {
    await register(userId, oppId, registerFlag)
  }
  // console.log("toggleRegistered")
}
